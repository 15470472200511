/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider) {
  $stateProvider.state('direct-deposit-form', {
    parent: 'authenticated',
    url: '/direct-deposit-form',
    templateProvider: function ($templateFactory, acpConfigurationService) {
      var brand;
      brand = acpConfigurationService.getBrand();

      if (brand === 'aceflare') {
        return $templateFactory.fromUrl(
          'sections/direct-deposit-form/templates/acp-direct-deposit-cdda-form.ng.html'
        );
      } else if (brand === 'ace') {
        return $templateFactory.fromUrl(
          'sections/direct-deposit-form/templates/acp-direct-deposit-gpr-form.ng.html'
        );
      } else {
        return $templateFactory.fromUrl(
          'sections/direct-deposit-form/templates/acp-direct-deposit-form.ng.html'
        );
      }
    },
    controller: 'AcpDirectDepositFormSectionCtrl',
    resolve: {
      module: /* @ngInject */ function ($ocLazyLoad) {
        return import(
          /* webpackChunkName: "acp.section.direct-deposit-form" */ 'sections/direct-deposit-form'
        ).then($ocLazyLoad.loadModule);
      }
    }
  });
}

export default states;
